import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaArrowRight } from '@react-icons/all-files/fa/FaArrowRight';

import Layout from '../components/Layout/Layout';
import HeroParticles from '../components/HeroParticles/HeroParticles';
import Section from '../components/Section/Section';
import IconButton from '../components/IconButton/IconButton';
import SimpleCard from '../components/Cards/SimpleCard';
import SimpleCardItem from '../components/Cards/SimpleCardItem';
import IconCard from '../components/Cards/IconCard';
import IconCardItem from '../components/Cards/IconCardItem';
import IconList from '../components/List/IconList';
import IconListItem from '../components/List/IconListItem';
import LogoCardItem from '../components/Cards/LogoCardItem';
import ImageCard from '../components/Cards/ImageCard';
import ImageCardItem from '../components/Cards/ImageCardItem';
import Carousel from '../components/Carousel/Carousel';
import Banner from '../components/Banner/Banner';
import Faqs from '../components/Faqs/Faqs';
import FaqSingle from '../components/Faqs/FaqSingle';
import FloatingMenu from '../components/FloatingMenu/FloatingMenu';
import Seo from '../components/Seo';

import portalIcon from '../images/portal-icon.svg';
import mobileIcon from '../images/mobile-icon.svg';
import payIcon from '../images/pay-icon.svg';
import schedulingIcon from '../images/scheduling-icon.svg';
import careIcon from '../images/care-icon.svg';
import intakeIcon from '../images/intake-icon.svg';
import taskingIcon from '../images/tasking-icon.svg';
import interfaceIcon from '../images/interface-icon.svg';
import infoIcon from '../images/info-icon.svg';
import clockIcon from '../images/clock-icon.svg';
import megaphoneIcon from '../images/megaphone-icon.svg';
import usersIcon from '../images/users-icon.svg';
import enhanceIcon from '../images/enhance-icon.svg';
import reduceIcon from '../images/reduce-icon.svg';
import elevateIcon from '../images/elevate-icon.svg';

import mweLogo from '../images/mwe-logo.svg';
import edgLogo from '../images/edg-logo.svg';
import maLogo from '../images/ma-logo.svg';
import fqhcLogo from '../images/fqhc-associates.png';
import infomedikaLogo from '../images/infomedika.png';
import ulrichLogo from '../images/ulrich.png';
import sunbitLogo from '../images/sunbit.svg';
import enciteLogo from '../images/encite.webp';
import wellskyLogo from '../images/wellsky.png';
import paiLogo from '../images/pai.png';

import awardOne from '../images/award-1.svg';
import awardTwo from '../images/award-2.svg';
import awardThree from '../images/award-3.svg';
import awardFour from '../images/award-4.svg';
import awardFive from '../images/award-5.svg';
import banner from '../images/banner-1.png';

const PatientEngagementSolution = () => {
  const slides = [
    {
      id: 'slide1',
      title: 'Medical Web Experts',
      text: 'Custom enterprise software solutions for healthcare.',
      uri: 'https://www.medicalwebexperts.com/',
      logo: mweLogo,
      button: 'Get custom software development for healthcare',
    },
    {
      id: 'slide2',
      title: 'Expert Demand Generation',
      text: 'Comprehensive healthcare digital marketing services.',
      uri: 'https://expertdemandgeneration.com/',
      logo: edgLogo,
      button: 'Explore healthcare marketing services',
    },
    {
      id: 'slide3',
      title: 'Medical Advantage',
      text: 'Maximize your performance in value-based care contracting.',
      logo: maLogo,
    },
    {
      id: 'slide4',
      title: 'FQHC.org',
      text: 'Advance your thought leadership in the FQHC market.',
      logo: fqhcLogo,
    },
    {
      id: 'slide5',
      title: 'Infomedika',
      text: 'Healthcare tech solutions for RCM optimization, EHR, and more.',
      logo: infomedikaLogo,
    },
    {
      id: 'slide6',
      title: 'Ulrich',
      text: 'BridgeInteract integrates with Ulrich’s Team Chart Concept (TCC) for EHR and practice management.',
      uri: '/blog/bridge-and-ulrich-unite-to-elevate-healthcare-technology-with-a-focus-on-user-experience/',
      logo: ulrichLogo,
      button: 'More about Bridge and Ulrich',
    },
    {
      id: 'slide7',
      title: 'Sunbit',
      text: 'Boost revenue with Sunbit’s financing tech, fully integrated with BridgeInteract.',
      uri: '/blog/bridge-partners-with-sunbit-to-integrate-financing-solutions-directly-into-the-patient-experience/',
      logo: sunbitLogo,
      button: 'More about Bridge and Sunbit',
    },
    {
      id: 'slide8',
      title: 'Encite',
      text: 'Our innovation partners. BridgeInteract is now available to all Encite clients.',
      uri: '/blog/bridge-and-encite-join-forces-to-revolutionize-healthcare-technology/',
      logo: enciteLogo,
      button: 'More about Bridge and Encite',
    },
    {
      id: 'slide9',
      title: 'WellSky',
      text: 'Leverage digital tools and analytics for connected value-based care.',
      logo: wellskyLogo,
    },
    {
      id: 'slide10',
      title: 'Practice Alternatives',
      text: 'Billing, EHR, practice management tools, powered by Bridge.',
      logo: paiLogo,
    },
  ];

  return (
    <Layout hasNavBg>
      <Seo
        title="Patient Engagement Software | Bridge"
        description="Enhance healthcare outcomes and save money with Bridge’s patient engagement solution. Simplify scheduling, intake, payments, communication, and more."
        canonical="/patient-engagement-solution/"
      />

      <HeroParticles
        title="Patient Engagement Software"
        subTitle="Successfully engage your patients across their entire healthcare journey with a consolidated platform."
        label="Request a Demo Now"
        uri="/view-a-demo/"
      />

      <Section variant="no-pt-top">
        <Container>
          <Row className="align-items-center">
            <Col className="mb-5 mb-lg-0" lg={6}>
              <StaticImage
                src="../images/proven-ways-to-boost-patient-engagement.jpg"
                alt=""
                className="rounded-xl"
              />
            </Col>
            <Col lg={6}>
              <p className="font-size-lg">
                Engaging patients is a must of modern healthcare; it ensures{' '}
                <a href="/blog/what-is-patient-engagement/">
                  better outcomes and higher satisfaction
                </a>
                . The BridgeInteract Patient Engagement Platform is designed to
                offer a comprehensive, all-in-one solution that streamlines{' '}
                <a href="/patient-messaging-solution/">communication</a>,{' '}
                <a href="/blog/how-to-improve-the-patient-experience/">
                  enhances the patient experience
                </a>
                , and improves operational efficiency. Discover how Bridge can
                transform patient engagement for your healthcare organization.
              </p>
            </Col>
          </Row>
        </Container>
      </Section>

      <FloatingMenu
        links={[
          'What Is A Patient Engagement Software?',
          'Welcome To BridgeInteract',
          'BridgeInteract Platform Modules',
          'Why Choose BridgeInteract',
          'Patient Engagement Best Practices',
          'Awards And Partnerships',
          'FAQs',
        ]}
      />

      <Section id="what-is-a-patient-engagement-software?" variant="sticky">
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bold">
                What Is A Patient Engagement Software?
              </h2>
              <p className="font-size-sm mb-4">
                Patient engagement software consists of all{' '}
                <a href="/blog/improving-patient-engagement-what-digital-tools-should-you-offer/">
                  digital tools designed to connect with patients
                </a>{' '}
                throughout the healthcare journey—before, during, and after
                their visit.
              </p>
              <p className="font-size-sm mb-4">
                Healthcare providers use patient engagement software to offer
                patients a more convenient, personalized experience at every key
                interaction—
                <a href="/patient-scheduling-software/">
                  appointment scheduling
                </a>
                , <a href="/patient-intake-software/">registration</a>,{' '}
                <a href="/patient-payments/">billing</a>, and more.
              </p>
              <p className="font-size-sm mb-0">
                By fostering greater patient engagement, healthcare
                organizations can:
              </p>
              <SimpleCard className="pt-5">
                <SimpleCardItem
                  col="4"
                  title="Enhance health outcomes:"
                  text="Patients who actively participate in their care are more likely to achieve better health outcomes."
                  icon={enhanceIcon}
                  className="mb-4 mb-lg-0"
                  showIcon
                />
                <SimpleCardItem
                  col="4"
                  title="Reduce healthcare costs:"
                  text="Engaged patients often make informed decisions, which can help lower overall healthcare expenses."
                  icon={reduceIcon}
                  className="mb-4 mb-lg-0"
                  showIcon
                />
                <SimpleCardItem
                  col="4"
                  title="Elevate the patient experience:"
                  text=" Patients who feel involved and empowered report higher satisfaction with their healthcare journey."
                  icon={elevateIcon}
                  showIcon
                />
              </SimpleCard>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section id="welcome-to-bridgeinteract" variant="bg-gray">
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bold">
                Benefits Of Patient Engagement Software
              </h2>
              <p className="font-size-sm mb-0">
                Patient engagement software simplifies every step of a complete
                treatment cycle, enabling healthcare organizations to:
              </p>
              <SimpleCard className="py-5">
                <SimpleCardItem
                  col="4"
                  title="Automate"
                  text={
                    <>
                      tasks such as{' '}
                      <a href="/patient-scheduling-software/">
                        appointment scheduling reminders
                      </a>
                      ,{' '}
                      <a href="/patient-intake-software/">
                        e-signature capture
                      </a>
                      , and patient data collection
                    </>
                  }
                  className="mb-4"
                />
                <SimpleCardItem
                  col="4"
                  title="Communicate"
                  text={
                    <>
                      securely with patients{' '}
                      <a href="/patient-engagement-mobile-app/">
                        on their preferred devices
                      </a>
                    </>
                  }
                  className="mb-4"
                />
                <SimpleCardItem
                  col="4"
                  title="Share"
                  text={
                    <>
                      <a href="/patient-messaging-solution/">
                        updates and education
                      </a>{' '}
                      with your patient community
                    </>
                  }
                  className="mb-4"
                />
                <SimpleCardItem
                  col="4"
                  title="Encourage"
                  text={
                    <>
                      <a href="/patient-scheduling-software/">
                        appointment scheduling
                      </a>
                      , follow-ups, and medication adherence
                    </>
                  }
                  className="mb-4"
                />
                <SimpleCardItem
                  col="4"
                  title="Enhance"
                  text="value-based care performance"
                  className="mb-4"
                />
                <SimpleCardItem
                  col="4"
                  title="Screen"
                  text="patients for medical needs and population risks for preventative and equitable care"
                  className="mb-4"
                />
                <SimpleCardItem
                  col="4"
                  title="Gather"
                  text="real-time data from EHRs"
                  className="mb-4 mb-lg-0"
                />
                <SimpleCardItem
                  col="4"
                  title="Analyze"
                  text="patient interaction data for actionable insights"
                />
              </SimpleCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="fw-bold text-center mt-3">Did You Know?</h2>
              <IconList className="grid mb-5">
                <IconListItem text="Online scheduling can save an average of 18 minutes per appointment" />
                <IconListItem text="Automated appointment reminders can reduce no-shows by up to 50%" />
                <IconListItem text="Patients using a patient portal generate 8-20% higher revenue for the clinic" />
              </IconList>
              <p className="font-size-sm">
                Some organizations have different solutions that focus on one or
                only a couple of the steps from the treatment cycle. This is an
                outdated approach. Patients and businesses expect the
                convenience of managing the entire patient engagement cycle in a
                consolidated platform.
              </p>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row className="align-items-center">
            <Col>
              <h2 className="fw-bold">Future-Proof Your Healthcare Practice</h2>
              <p className="font-size-sm">
                In the dynamic world of healthcare, having a robust and
                intuitive platform that simplifies and enhances the patient
                journey is paramount.
              </p>
            </Col>
          </Row>
          <Row className="align-items-center mb-4 mb-lg-0">
            <Col className="my-4 pe-lg-5" lg={8}>
              <h4 className="fw-bold">Complete Patient Engagement Software</h4>
              <p className="font-size-sm">
                The BridgeInteract platform contains the necessary tools for
                every aspect of patient engagement. Our SaaS solution is
                designed to unify and streamline workflows between patients and
                healthcare providers, forming an end-to-end system where all
                connecting parts ‘just work’ together seamlessly. Our desktop
                and mobile approach ensures that patients and healthcare
                providers can connect anytime, anywhere, enhancing engagement
                and satisfaction.
              </p>
            </Col>
            <Col className="my-4" lg={4}>
              <div className="img-ratio h-0">
                <StaticImage
                  src="../images/complete-patient.png"
                  alt=""
                  className="position-absolute top-0 start-0 w-100 h-100 rounded-xl"
                />
              </div>
            </Col>
          </Row>
          <Row className="align-items-center flex-column-reverse flex-lg-row mb-4 mb-lg-0">
            <Col className="my-4" lg={4}>
              <div className="img-ratio h-0">
                <StaticImage
                  src="../images/composable-patient.png"
                  alt=""
                  className="position-absolute top-0 start-0 w-100 h-100 rounded-xl"
                />
              </div>
            </Col>
            <Col className="my-4 ps-lg-5" lg={8}>
              <h4 className="fw-bold">
                Composable Patient Engagement Software
              </h4>
              <p className="font-size-sm">
                With customizable workflows and a user-centric design, our
                platform adapts to the unique needs of each practice, from small
                clinics to expansive hospital networks.
              </p>
              <p className="font-size-sm">
                <b>BridgeInteract is scalable:</b> Clients can use the full
                platform or select key modules they need now, ensuring that they
                get the most cost-effective solution for their current
                organizational needs with the possibility to scale in the
                future.
              </p>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className="my-4 pe-lg-5" lg={8}>
              <h4 className="fw-bold">
                Integrated Patient Engagement Software
              </h4>
              <p className="font-size-sm">
                BridgeInteract integrates with{' '}
                <a href="/integration-services/">
                  any EHR, RCM, PM, HIE, and RIS system.
                </a>
              </p>
              <p className="font-size-sm">
                The future of healthcare demands a seamlessly integrated, fully
                automated, and exceptionally effective approach to patient
                engagement. Whether you’re looking for a{' '}
                <a href="/patient-engagement-platforms-compared/">
                  complete patient engagement software package
                </a>{' '}
                or simply want to bridge the gaps between your existing
                piecemeal solutions, BridgeInteract is here for you.
              </p>
            </Col>
            <Col className="my-4" lg={4}>
              <div className="img-ratio h-0">
                <StaticImage
                  src="../images/integrated-patient.png"
                  alt=""
                  className="position-absolute top-0 start-0 w-100 h-100 rounded-xl"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section id="bridgeinteract-platform-modules" variant="bg-gray">
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bold text-center">
                BridgeInteract Platform Modules
              </h2>
              <ImageCard className="gap-4 py-5">
                <ImageCardItem
                  uri="/patient-portal-software/"
                  title="Portal"
                  suTitle="Bridge"
                  text="Complete patient portal software for engagement, communication, education, and more."
                  image={portalIcon}
                />
                <ImageCardItem
                  uri="/patient-engagement-mobile-app/"
                  title="Mobile"
                  suTitle="Bridge"
                  text="Publish your own branded digital patient engagement mobile app for a truly outstanding patient experience on mobile devices."
                  image={mobileIcon}
                />
                <ImageCardItem
                  uri="/patient-payments/"
                  title="Pay"
                  suTitle="Bridge"
                  text="Streamlined, flexible online patient payment software."
                  image={payIcon}
                />
                <ImageCardItem
                  uri="/patient-scheduling-software/"
                  title="Scheduling"
                  suTitle="Bridge"
                  text="Easy patient scheduling software integrated into your EHR/PMS."
                  image={schedulingIcon}
                />
                <ImageCardItem
                  uri="/telehealth-solution/"
                  title="Virtual Care"
                  suTitle="Bridge"
                  text="Video conferencing, live chat, and other tools for virtual healthcare visits."
                  image={careIcon}
                />
                <ImageCardItem
                  uri="/patient-intake-solution/"
                  title="Intake"
                  suTitle="Bridge"
                  text="Efficient patient check-in and registration."
                  image={intakeIcon}
                />
                <ImageCardItem
                  uri="/task-management/"
                  title="Tasking"
                  suTitle="Bridge"
                  text="Manage healthcare tasks seamlessly."
                  image={taskingIcon}
                />
                <ImageCardItem
                  uri="/integration-services/"
                  title="Interface"
                  suTitle="Bridge"
                  text="Integrate with existing EHR, RCM, PM, HIE, RIS, and CRM systems."
                  image={interfaceIcon}
                />
              </ImageCard>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row className="flex-column-reverse flex-lg-row align-items-center">
            <Col lg={6}>
              <h3 className="fw-bold">Bridge Portal</h3>
              <p className="font-size-sm">
                Effortlessly manage every aspect of patient care.
              </p>
              <p className="font-size-sm">
                Meet your patients where and how they want to be met with a
                user-friendly portal that does it all. Much more than your
                typical EHR-bundled portal, Bridge Portal, an{' '}
                <a href="/certifications/">
                  ONC-certified patient portal software
                </a>
                , offers advanced patient care features, seamless EHR/PM
                integration, and top-tier security.
              </p>
              <p className="font-size-sm">
                Bridge Portal connects all patient-facing digital tools,
                including:
              </p>
              <IconList>
                <IconListItem
                  text="HIPAA-compliant secure patient/provider messaging"
                  uri="/hipaa-compliant-messaging/"
                  isOrange
                />
                <IconListItem
                  text="Medical records access"
                  uri="/online-medical-record-personal-health-record-phr-portal/"
                  isOrange
                />
                <IconListItem
                  text="Broadcast messaging"
                  uri="/patient-broadcast-mass-messaging/"
                  isOrange
                />
                <IconListItem
                  text="Appointment scheduling"
                  uri="/patient-scheduling-software/"
                  isOrange
                />
                <IconListItem
                  text={
                    <>
                      Notification engine (for{' '}
                      <a href="/patient-appointment-reminder-solution/">
                        appointments
                      </a>
                      , <a href="/patient-payments/">bills</a>, and more)
                    </>
                  }
                />
                <IconListItem
                  text="Patient forms"
                  uri="/patient-intake-software/"
                  isOrange
                />
                <IconListItem
                  text="Proxy/family account access"
                  uri="/caregiver-proxy-patient-portal-access/"
                  isOrange
                />
                <IconListItem
                  text="Prescription refill requests"
                  uri="/prescription-rx-refill-renewal-request/"
                  isOrange
                />
              </IconList>
              <IconButton
                className="c-icon-button__big mt-2"
                href="/patient-portal-software/"
              >
                Discover Bridge Portal{' '}
                <FaArrowRight size={16} className="ms-2" />
              </IconButton>
            </Col>
            <Col className="text-center" lg={6}>
              <img
                src={portalIcon}
                alt=""
                className="w-100 p-5 p-lg-0 mb-3 mb-lg-0"
              />
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row className="align-items-center">
            <Col className="text-center" lg={6}>
              <img
                src={mobileIcon}
                alt=""
                className="w-100 p-5 p-lg-0 mb-3 mb-lg-0"
              />
            </Col>
            <Col lg={6}>
              <h3 className="fw-bold">Bridge Mobile</h3>
              <p className="font-size-sm">
                Provide access anywhere, anytime—on their mobile devices via a
                client-branded{' '}
                <a href="/patient-engagement-mobile-app/">
                  patient engagement mobile app for iOS and Android
                </a>
                . You can select from existing features (and develop new custom
                features) to offer patients a{' '}
                <a href="/blog/how-healthcare-mobile-applications-prevent-medication-lapse-update-2022-bridge/">
                  convenient, all-in-one app
                </a>{' '}
                for managing their healthcare needs. In the highly
                consumer-centric mHealth space, Bridge Mobile allows healthcare
                organizations to quickly launch a feature-rich branded app,
                enhancing patient convenience, building brand loyalty, and{' '}
                <a href="/blog/how-healthcare-mobile-applications-prevent-medication-lapse-update-2022-bridge/">
                  increasing medication/appointment adherence
                </a>
                .
              </p>
              <p className="font-size-sm">Accessible in English and Spanish.</p>
              <IconButton
                className="c-icon-button__big mt-2"
                href="/patient-engagement-mobile-app/"
              >
                Your Mobile Solution <FaArrowRight size={16} className="ms-2" />
              </IconButton>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row className="flex-column-reverse flex-lg-row align-items-center">
            <Col lg={6}>
              <h3 className="fw-bold">Bridge Pay</h3>
              <p className="font-size-sm">
                Bridge Pay simplifies patient payments to ensure that you get
                paid, on time, by satisfied customers. By offering a secure and
                convenient way to{' '}
                <a href="/patient-bill-pay-software/">
                  pay medical bills online or in person
                </a>
                , boosted by automated reminders and supported by eligibility,
                financing, and financial management tools, you benefit from:
              </p>
              <IconList>
                <IconListItem text="Faster collections" />
                <IconListItem text="Increased revenue" />
                <IconListItem text="Fewer merchant fees" />
                <IconListItem text="Less admin" />
              </IconList>
              <p className="font-size-sm">
                Integrating with leading RCM and PM systems, Bridge Pay provides
                real-time financial transparency, allowing patients to view
                balances, line items, and payment history securely. Online
                payments can be made through your website, patient portal, and
                mobile app, even outside business hours.
              </p>
              <IconButton
                className="c-icon-button__big mt-2"
                href="/patient-payments/"
              >
                Patient Payment Made Easy{' '}
                <FaArrowRight size={16} className="ms-2" />
              </IconButton>
            </Col>
            <Col className="text-center" lg={6}>
              <img
                src={payIcon}
                alt=""
                className="w-100 p-5 p-lg-0 mb-3 mb-lg-0"
              />
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row className="align-items-center">
            <Col className="text-center" lg={6}>
              <img
                src={schedulingIcon}
                alt=""
                className="w-100 p-5 p-lg-0 mb-3 mb-lg-0"
              />
            </Col>
            <Col lg={6}>
              <h3 className="fw-bold">Bridge Scheduling</h3>
              <p className="font-size-sm">
                Reduce no-shows, free up staff time, and increase repeat visits
                with{' '}
                <a href="/patient-scheduling-software/">
                  patient scheduling software
                </a>
                . Provide patients with a simple way to manage appointments from
                anywhere, 24/7. Using Bridge Scheduling, patients can schedule,
                cancel, or request an appointment directly from their devices
                via your website, portal, or branded app. Reduce your
                administrative burden with automated scheduling processes and
                reminders that eliminate the need for patients to call during
                office hours, helping to avoid errors and last-minute
                cancellations.
              </p>
              <IconButton
                className="c-icon-button__big mt-2"
                href="/patient-scheduling-software/"
              >
                Simplify Self-Scheduling{' '}
                <FaArrowRight size={16} className="ms-2" />
              </IconButton>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row className="flex-column-reverse flex-lg-row align-items-center">
            <Col lg={6}>
              <h3 className="fw-bold">Bridge Virtual Care</h3>
              <p className="font-size-sm">
                With Bridge Virtual Care, you can offer convenient telehealth
                services and virtual visits. Online or hybrid visits in Bridge
                Virtual Care follow the same digital check-in process as
                in-office appointments, integrating seamlessly with EHR, PM, and
                RCM systems. Our{' '}
                <a href="/patient-portal-software/">mobile-responsive design</a>{' '}
                enables patients to complete their virtual visit workflow from
                any device.
              </p>
              <p className="font-size-sm">Features include:</p>
              <IconList>
                <IconListItem text="Custom workflows prioritizing virtual visits" />
                <IconListItem text="Online forms for pre-visit completion" />
                <IconListItem text="Third-party access for caregivers and interpreters" />
                <IconListItem text="Live chat for pre-session communication" />
              </IconList>
              <IconButton
                className="c-icon-button__big mt-2"
                href="/telehealth-solution/"
              >
                Get HIPAA-Compliant Telehealth{' '}
                <FaArrowRight size={16} className="ms-2" />
              </IconButton>
            </Col>
            <Col className="text-center" lg={6}>
              <img
                src={careIcon}
                alt=""
                className="w-100 p-5 p-lg-0 mb-3 mb-lg-0"
              />
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <img src={intakeIcon} alt="" className="w-100" />
            </Col>
            <Col lg={6}>
              <h3 className="fw-bold">Bridge Intake</h3>
              <p className="font-size-sm">
                Streamline workflows, reduce wait times, and enhance patient
                satisfaction by enabling patients to complete all necessary
                forms online before their appointments.
              </p>
              <p className="font-size-sm">
                Our{' '}
                <a href="/patient-intake-software/">
                  industry-leading intake solution
                </a>{' '}
                supports real-time data integration with EHR/PM systems for data
                and payment processing. Other features include:
              </p>
              <IconList>
                <IconListItem
                  text="Patient self-registration"
                  uri="/patient-portal-self-registration-login/"
                  isOrange
                />
                <IconListItem
                  text="E-signature capture"
                  uri="/patient-intake-software/"
                  isOrange
                />
                <IconListItem text="Insurance verification, co-payment collection, and outstanding balances" />
                <IconListItem text="Secure digital consent forms" />
                <IconListItem text="Customizable forms" />
                <IconListItem
                  text={
                    <>
                      Virtual waiting room with{' '}
                      <a href="/personalized-patient-education/">
                        educational materials
                      </a>
                    </>
                  }
                />
              </IconList>
              <p className="font-size-sm">
                Accessible on any device, Bridge Intake improves efficiency,
                data accuracy, and patient convenience, setting a positive tone
                for the entire care journey.
              </p>
              <IconButton
                className="c-icon-button__big mt-2"
                href="/patient-intake-solution/"
              >
                Enhance Patient Intake{' '}
                <FaArrowRight size={16} className="ms-2" />
              </IconButton>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row className="flex-column-reverse flex-lg-row align-items-center">
            <Col lg={6}>
              <h3 className="fw-bold">Bridge Tasking</h3>
              <p className="font-size-sm">
                Boost efficiency with smart automation.
              </p>
              <p className="font-size-sm">
                Enhance productivity, improve data accuracy, and reduce errors
                by consolidating patient requests and portal tasks into a{' '}
                <a href="/healthcare-task-management/">
                  single healthcare task management solution
                </a>
                .
              </p>
              <p className="font-size-sm">
                Bridge Tasking automatically creates, prioritizes, and assigns
                tasks based on custom criteria and events from the
                BridgeInteract platform, streamlining workflows in a similar way
                to a support ticket system.
              </p>
              <IconButton
                className="c-icon-button__big mt-2"
                href="/task-management/"
              >
                Streamline Your Workflows{' '}
                <FaArrowRight size={16} className="ms-2" />
              </IconButton>
            </Col>
            <Col lg={6}>
              <img src={taskingIcon} alt="" className="w-100" />
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <img src={interfaceIcon} alt="" className="w-100" />
            </Col>
            <Col lg={6}>
              <h3 className="fw-bold">Bridge Interface</h3>
              <p className="font-size-sm">
                Connect seamlessly with your existing systems.
              </p>
              <p className="font-size-sm">
                Enhance interoperability and streamline data exchange across all
                your healthcare systems with our integration services. Bridge
                Interface{' '}
                <a href="/integration-services/">
                  supports integration with EHR, PM, RCM, LIS, and RIS systems
                </a>{' '}
                using technologies like FHIR, HL7, and REST API, ensuring
                seamless communication and better patient care. The software
                meets regulatory compliance, supports the latest USCDI data set,
                and enables real-time data display from EHRs.
              </p>
              <p className="font-size-sm">
                You can also count on our responsive customer support for setup,
                deployment, and maintenance, ensuring a hassle-free integration
                process.
              </p>
              <IconButton
                className="c-icon-button__big mt-2"
                href="/integration-services/"
              >
                Explore Our Integration Services{' '}
                <FaArrowRight size={16} className="ms-2" />
              </IconButton>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section id="why-choose-bridgeinteract">
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bold">
                Integrated Care, Elevated Outcomes, Affordable Prices
              </h2>
              <p className="font-size-sm mb-5">
                Bridge sets a new standard in patient engagement software by
                consolidating disparate healthcare journey touchpoints into one
                integrated and automated solution.
              </p>
              <h4 id="cost-effective-solutions" className="fw-bold">
                Cost-Effective Solutions
              </h4>
              <p className="font-size-sm">
                BridgeInteract is a foundational, scalable patient engagement
                platform that grows with your clinic, hospital, or integrated
                delivery network (IDN). The platform supports real-time patient
                engagement across multiple facilities.
              </p>
              <p className="font-size-sm mb-0">
                Maintaining multiple patient engagement systems is costly and
                inefficient. BridgeInteract{' '}
                <a href="/blog/consolidating-patient-engagement-systems-into-a-healthcare-digital-front-door/">
                  consolidates these functionalities into one platform
                </a>
                , reducing high licensing costs and simplifying the patient
                experience. By replacing fragmented systems with a single,
                integrated patient engagement solution, BridgeInteract ensures
                all patient engagement activities are centralized,{' '}
                <a href="/revenue-cycle-management/">
                  reducing inefficiencies in the revenue cycle
                </a>{' '}
                and improving communication.
              </p>
            </Col>
          </Row>
          <IconCard className="py-5">
            <IconCardItem
              col="6"
              title="Reduce"
              texts={[
                'Licensing costs and wastage',
                'Problems with registration and password resets',
                'Administrative burden',
              ]}
            />
            <IconCardItem
              col="6"
              title="Increase"
              texts={[
                'Patient satisfaction and retention',
                'RCM efficiency',
                'Positive health outcomes',
              ]}
              showUpArrow
              grow
            />
          </IconCard>
          <Row>
            <Col>
              <p className="font-size-sm">
                Patients no longer need to manage multiple logins for various
                portals and services, which streamlines registration and reduces
                the burden on healthcare providers. Verification is handled
                using the{' '}
                <a href="/patient-engagement-mobile-app/">
                  patient’s phone number or face recognition
                </a>
                , ensuring that they will always have their credentials on hand
                via their mobile device when they need them. Finally,
                BridgeInteract offers a single “opt-in” registry for
                communication preferences, ensuring consistent and preferred
                patient interactions.
              </p>
            </Col>
          </Row>
          <Row id="value-based-care" className="align-items-center mt-4">
            <Col lg={5}>
              <StaticImage
                src="../images/targeted-engagement.jpg"
                alt=""
                className="rounded-xl mb-5 mb-lg-0"
              />
            </Col>
            <Col lg={7}>
              <h4 className="fw-bold">
                Targeted Engagement To Improve Value-Based Care
              </h4>
              <p className="font-size-sm">
                By maintaining constant communication with patients, driving
                follow-up appointments, and enhancing quality performance,
                Bridge’s patient engagement software helps healthcare
                organizations attract and retain patients, ensuring targeted,
                preventative care and improved outcomes—all important elements
                of value-based care assessments. Furthermore, by providing an
                accessible platform for patients to view, download, and engage
                with their health information, BridgeInteract helps providers{' '}
                <a href="/blog/how-patient-engagement-platforms-optimize-patient-centered-care/">
                  meet and exceed patient engagement metrics for reimbursement
                  according to MACRA requirements
                </a>
                .
              </p>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section id="patient-engagement-best-practices">
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bold">
                Best Practices For Patient Activation And Engagement
              </h2>
              <p className="font-size-sm mb-0">
                <a href="/blog/creating-a-successful-patient-engagement-strategy/">
                  Successful patient engagement
                </a>{' '}
                requires both{' '}
                <a href="/blog/4-patient-engagement-tools-used-to-increase-patient-portal-usage/">
                  strategy and the right technology
                </a>
                . It’s no longer enough to simply offer a patient portal,
                especially one that doesn’t engage its users—we’re here to help
                you deliver an outstanding user experience that patients and
                providers will love.
              </p>
            </Col>
          </Row>
          <IconCard className="py-5">
            <IconCardItem
              col="6"
              titleTop="Activate"
              textTop={
                <>
                  Better patient engagement starts with{' '}
                  <strong>patient activation</strong>—empowering patients with
                  the knowledge, skills, and tools they need to actively
                  participate in their care. User-friendly digital health tools
                  play a key role in this, especially self-service tools,
                  <a href="/personalized-patient-education/">
                    educational resources
                  </a>
                  , and access to personal health records.
                </>
              }
              title="Patient Activation"
              texts={[
                'Provide <strong>knowledge</strong>',
                'Foster <strong>skills</strong>',
                'Offer <strong>tools</strong>',
              ]}
              showTopSection
            />
            <IconCardItem
              col="6"
              titleTop="Engage"
              textTop={
                <>
                  <strong>Patient engagement</strong> is the cultivation of a
                  relationship—it depends on ongoing communication,
                  collaboration, and shared-decision making. A patient
                  engagement software solution facilitates this relationship by
                  making communication as frictionless as possible and placing
                  key decisions at the patient’s fingertips.
                </>
              }
              title="Patient Engagement"
              texts={[
                'Facilitate <strong>communication</strong>',
                'Encourage <strong>collaboration</strong>',
                'Maintain <strong>participation</strong>',
              ]}
              showTopSection
            />
          </IconCard>
          <Row className="mt-4">
            <Col>
              <h4 className="fw-bold">The 4 Pillars Of Patient Engagement</h4>
              <p className="font-size-sm mb-0">
                BridgeInteract’s robust feature set aligns with{' '}
                <a href="/blog/how-can-i-increase-patient-engagement/">
                  best practices to achieve patient engagement goals
                </a>
                , removing barriers to accessibility and adoption thanks to:
              </p>
              <ul>
                <li className="font-size-sm">
                  <a href="/blog/5-patient-engagement-ideas-adapting-consumer-healthcare-needs-now-post-pandemic/">
                    Features patients want
                  </a>
                  —EHR, patient education, and self-service tools
                </li>
                <li className="font-size-sm">
                  Convenient, secure access for patients via the web and mobile
                </li>
                <li className="font-size-sm">
                  Secure, HIPAA-compliant communication tools that keep the
                  patient informed and promote action through reminders and
                  notifications
                </li>
                <li className="font-size-sm">
                  Streamlined <a href="/patient-intake-solution/">workflows</a>{' '}
                  for staff and physician buy-in
                </li>
              </ul>
            </Col>
          </Row>
          <SimpleCard className="py-5">
            <SimpleCardItem
              col="6"
              title="Meaningful Info"
              text="The application provides patients with meaninfgul information that is actually useful to them, eg. their lab results or medical records. This could be information about the patient (such as lab results or medical records), or self-service tools that help make tasks for managing their health easier (such as schedule an appointment, paying a bill, or messaging their provider)."
              icon={infoIcon}
              className="mb-4"
              showIcon
            />
            <SimpleCardItem
              col="6"
              title="Accessibility"
              text="The application can be accessed 24/7 and easily (withouth cumbersome login requirements, for example). The application is available in the form of a web application as well as an iOS or Android app."
              icon={clockIcon}
              className="mb-4"
              showIcon
            />
            <SimpleCardItem
              col="6"
              title="Promotion"
              text="The application and the features of the application have been adequately promoted to the patient, both online and in-office- Automated marketing is also leveraged to promote the application, includin messages before/after an appointment or when the patient is due for an appointment."
              icon={megaphoneIcon}
              className="mb-4 mb-lg-0"
              showIcon
            />
            <SimpleCardItem
              col="6"
              title="Staff Buy-In"
              text="Staff and provider are familiar with the application and its features. They take part in promoting it and answering questions from patients about how it could be used. The application is a part o the care plan or the patient - for example, providers prescribe patient education or send messages with post-visit instructions via the application."
              icon={usersIcon}
              showIcon
            />
          </SimpleCard>
        </Container>
      </Section>

      <Section id="awards-and-partnerships">
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bold">Award-Winning Patient Engagement</h2>
            </Col>
          </Row>
          <Row className="my-5">
            <Col className="c-partners-logo d-flex justify-content-between flex-column flex-lg-row">
              <div className="text-center p-5 p-lg-0">
                <img src={awardOne} alt="" />
              </div>
              <div className="text-center p-5 p-lg-0">
                <img src={awardTwo} alt="" />
              </div>
              <div className="text-center p-5 p-lg-0">
                <img src={awardThree} alt="" />
              </div>
              <div className="text-center p-5 p-lg-0">
                <img src={awardFour} alt="" />
              </div>
              <div className="text-center p-5 p-lg-0">
                <img src={awardFive} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bold">Our Partners</h2>
              <p className="font-size-sm">
                Bridge partners with leading industry experts to deliver
                additional value and offer advanced tech solutions in the
                healthcare sector. Whether through web app development,
                marketing services, payment partnerships, or software
                integrations, Bridge continues to advance provider success and
                patient satisfaction.
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="p-0">
              <Carousel className="pt-4 px-3" loop spaceBetween={20}>
                {slides.map((slide) => (
                  <LogoCardItem
                    key={slide.id}
                    title={slide.title}
                    text={slide.text}
                    uri={slide.uri}
                    logo={slide.logo}
                    button={slide.button}
                  />
                ))}
              </Carousel>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row>
            <Col>
              <Banner
                title="Ready To Revolutionize Your Patient Engagement?"
                text="Click below to request a demo and see how BridgeInteract can work for your healthcare organization. Click below to request a demo and see how BridgeInteract can work for your healthcare organization."
                label="Request a Demo"
                uri="/view-a-demo/"
                image={banner}
              />
            </Col>
          </Row>
        </Container>
      </Section>

      <Section id="faqs">
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bold">FAQs</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <Faqs className="mb-5">
                <FaqSingle question="How Much Does Patient Engagement Cost?">
                  <p className="font-size-sm">
                    The cost of patient engagement software can vary
                    significantly. Third-party technology vendors often set
                    their fees based on features, usage, the size of your
                    organization, and other factors.
                  </p>
                  <p className="font-size-sm">
                    Most patient engagement systems offer core functionalities
                    like patient messaging. However, the total cost depends on
                    the additional features you choose to implement, such as:
                  </p>
                  <ul>
                    <li className="font-size-sm">
                      Self-scheduling and appointment request functionality
                    </li>
                    <li className="font-size-sm">
                      Automated appointment reminders and confirmations
                    </li>
                    <li className="font-size-sm">
                      Digital check-in and pre-visit registration capability
                    </li>
                    <li className="font-size-sm">
                      Online payment options and digital statements
                    </li>
                    <li className="font-size-sm">
                      Post-visit patient satisfaction surveys
                    </li>
                    <li className="font-size-sm">
                      Templates for outreach content
                    </li>
                    <li className="font-size-sm">
                      Real-time analytics and reporting tools
                    </li>
                  </ul>
                  <p className="font-size-sm">
                    When comparing the pricing of different patient engagement
                    vendors, consider these factors:
                  </p>
                  <ul>
                    <li className="font-size-sm">
                      <strong>Flat-rate vs flexible pricing:</strong> Flat
                      charges can be easier to budget for but may
                      disproportionately impact smaller healthcare
                      organizations, whereas flexible pricing can be scaled up
                      or down according to your needs.
                    </li>
                    <li className="font-size-sm">
                      <strong>Bundle vs piecemeal purchases:</strong> Some
                      vendors may offer a bundle of services at a discounted
                      rate compared to purchasing individual modules. Others may
                      only offer bundles that contain unnecessary items, making
                      them less cost-effective.
                    </li>
                    <li className="font-size-sm">
                      <strong>HIPAA-compliance:</strong> Any reputable patient
                      engagement solution should be HIPPA-compliant to secure
                      patient data and protect your organization from legal
                      liability.
                    </li>
                    <li className="font-size-sm">
                      <strong>Integration:</strong> Does the software integrate
                      bi-directionally with your EHR or PM system? If not, can a
                      custom integration be developed?
                    </li>
                    <li className="font-size-sm">
                      <strong>Long-term support:</strong> Does the vendor offer
                      long-term support, and to what extent?
                    </li>
                    <li className="font-size-sm">
                      <strong>Innovation:</strong> Choose a vendor that
                      continuously updates the software to grow with market-wise
                      technology upgrades rather than a vendor that is unwilling
                      to invest in the release of new features and updates that
                      evolve with the market.
                    </li>
                  </ul>
                </FaqSingle>
                <FaqSingle question="How Much Does BridgeInteract Cost?">
                  <p className="font-size-sm">
                    BridgeInteract Patient Engagement platform is designed to be
                    flexible and scalable, tailored to the specific needs of
                    each healthcare organization. Pricing is determined by the
                    modules selected, the level of customization required, and
                    the scale of the organization. This approach ensures that
                    client’s resources are invested in exactly what they need
                    and avoids paying for unnecessary components.
                  </p>
                  <p className="font-size-sm">
                    Significant elements that factor into the costing of
                    BridgeInteract include:
                  </p>
                  <ul>
                    <li className="font-size-sm">
                      <strong>Type of EHR interface required:</strong> The
                      complexity of integration, whether through FHIR, Rest API,
                      or HL7, and the specific patient engagement functionality
                      needed, drives the interface requirements and,
                      consequently, the cost.
                    </li>
                    <li className="font-size-sm">
                      <strong>Custom intake workflows:</strong> Time for
                      developing complex intake workflows, including decision
                      trees, dynamic forms, and other logic-based processes, is
                      factored into the overall cost of the project.
                      Additionally, how these intake forms integrate with the
                      EHR—whether through simple PDF exports or more advanced
                      discrete data field integration—further influences the
                      pricing.
                    </li>
                    <li className="font-size-sm">
                      <strong>Custom scheduling workflows:</strong> Implementing
                      self-scheduling functionalities involves intricate rules
                      and logic to ensure that patients can only book
                      appointments that require no staff intervention and are
                      aligned with provider availability and location. The
                      complexity of these scheduling workflows will be factored
                      into BridgeInteract’s pricing.
                    </li>
                  </ul>
                  <p className="font-size-sm">
                    Bridge works closely with all clients to ensure satisfaction
                    and value for money at every organization’s scale and
                    budget. To obtain a personalized quotation, we invite you to{' '}
                    <a href="/view-a-demo/">contact us directly</a>. You can see
                    the platform in action by arranging a demo and discussing
                    your unique needs with our experts. Bridge is committed to
                    providing solutions that deliver value and improve patient
                    outcomes, tailored to your operational scale and scope.
                  </p>
                </FaqSingle>
                <FaqSingle question="How is Bridge different from other patient engagement solution vendors?">
                  <p className="font-size-sm">
                    Bridge is uniquely positioned to deliver an exceptional{' '}
                    <a href="/patient-engagement-platform/">
                      patient engagement platform
                    </a>{' '}
                    to healthcare organizations.
                  </p>
                  <p className="font-size-sm">
                    What sets Bridge apart from competitors:
                  </p>
                  <ul>
                    <li className="font-size-sm">
                      <strong>Cost-effective pricing</strong> model–cheaper than
                      competitors, pay only for what you need to use.
                    </li>
                    <li className="font-size-sm">
                      Complete system, able to serve as an{' '}
                      <strong>all-in-one solution</strong> for all your patient
                      engagement needs.
                    </li>
                    <li className="font-size-sm">
                      Ability to navigate interface challenges with EHR/RCM
                      vendors, having successfully interfaced with{' '}
                      <strong>15+ EHR/RCM solutions</strong>.
                    </li>
                    <li className="font-size-sm">
                      Utilization of the latest technologies, such as Angular
                      and Node.js, which allows for a single codebase for{' '}
                      <strong>web and mobile</strong>, and the publishing of iOS
                      and Android apps.
                    </li>
                    <li className="font-size-sm">
                      Experience completing many complex projects. Bridge enters
                      a project with confidence and is comfortable setting
                      budget limits, preventing overspending.
                    </li>
                    <li className="font-size-sm">
                      Focus on the ever-growing needs of medium-to-large-sized
                      healthcare businesses.
                    </li>
                    <li className="font-size-sm">
                      Advanced expertise in the healthcare regulatory landscape
                      (i.e., HIPAA, ADA, CCPA), vetted by industry authorities.
                    </li>
                    <li className="font-size-sm">
                      Expertise in healthcare interface technology (i.e., HL7,
                      FHIR, CCD, direct database).
                    </li>
                    <li className="font-size-sm">
                      Extensive experience in providing patient portals and
                      apps, with in-depth knowledge of the age of majority law,
                      acceptable management of proxy accounts, conditional
                      presentation of sensitive information, and the
                      complexities associated with{' '}
                      <strong>parent/guardian/caregiver access</strong>.
                    </li>
                  </ul>
                  <p className="font-size-sm">
                    As the healthcare industry grows in complexity, it’s crucial
                    to have a partner to optimize communication and
                    interoperability between systems. Through{' '}
                    <strong>high R&D spending (50% of expenses)</strong>, Bridge
                    has been able to thrive in the ever-changing, disorganized
                    healthcare market. Meanwhile, other vendors are struggling
                    to keep up with customer needs and have significantly higher
                    operating costs, high customer acquisition costs, and
                    obsolete software platforms.
                  </p>
                </FaqSingle>
                <FaqSingle question="Does Bridge offer additional software development services?">
                  <p className="font-size-sm">
                    Absolutely. Through our partnership with{' '}
                    <a
                      href="https://www.medicalwebexperts.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Medical Web Experts
                    </a>{' '}
                    (MWE), Bridge offers a wide range of custom software
                    development services, from{' '}
                    <a
                      href="https://www.medicalwebexperts.com/healthcare-website-design/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      healthcare website design
                    </a>{' '}
                    to custom healthcare app development, additional
                    integrations and more.
                  </p>
                  <p className="font-size-sm">
                    Medical Web Experts has over fifteen years of experience in
                    medical web/portal design and app development for the
                    healthcare industry, working with some of the industry’s
                    largest health systems. Through our partnership, Bridge
                    clients can build custom solutions that optimize and expand
                    the functionality of BridgeInteract to meet their specific
                    business goals.
                  </p>
                </FaqSingle>
                <FaqSingle question="Does Bridge offer marketing services?">
                  <p className="font-size-sm">
                    Yes. We know that the success of technology is closely
                    related to the adoption and use of this technology by your
                    staff and your patients. Through our partnership with Expert
                    Demand Generation (EDG), Bridge offers a suite of{' '}
                    <a
                      href="https://expertdemandgeneration.com/healthcare-marketing-services/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      healthcare marketing
                    </a>{' '}
                    services to ensure maximum engagement across the patient
                    population, including:
                  </p>
                  <ul>
                    <li>Technology or services launch communication plans</li>
                    <li>Patient technology adoption</li>
                    <li>Internal change management</li>
                    <li>Patient acquisitio</li>
                  </ul>
                  <p className="font-size-sm">
                    Our partners’ digital marketing services are specifically
                    designed to maximize the value of BridgeInteract for your
                    business, helping you drive awareness and adoption of your
                    services. Their services however extend also to 360
                    communication with your patients or potential targets to
                    grow your business as well.{' '}
                  </p>
                </FaqSingle>
                <FaqSingle question="What technologies does Bridge currently interface with?">
                  <p className="font-size-sm">
                    Bridge currently interfaces with:
                  </p>
                  <ul>
                    <li className="font-size-sm">AdvancedMD</li>
                    <li className="font-size-sm">
                      <a href="/blog/better-patient-experiences-with-bridge-patient-portals-allscripts-interface/">
                        Allscripts
                      </a>
                    </li>
                    <li className="font-size-sm">
                      <a href="/blog/better-patient-experiences-with-bridge-patient-portals-athenahealth-interface/">
                        athenahealth
                      </a>{' '}
                      (Incl. athenaOne and the Centricity product line, formerly
                      known as Virence /{' '}
                      <a href="/blog/better-patient-experiences-with-bridge-patient-portals-ge-centricity-interface/">
                        GE Centricity
                      </a>{' '}
                      incl. Centricity Business, Centricity EMR, and Centricity
                      Practice Solution (CPS))
                    </li>
                    <li className="font-size-sm">Aurora</li>
                    <li className="font-size-sm">Brightree</li>
                    <li className="font-size-sm">EMR-Link</li>
                    <li className="font-size-sm">gMed</li>
                    <li className="font-size-sm">
                      <a href="/blog/better-patient-experiences-with-bridge-patient-portals-greenway-health-interface/">
                        Greenway
                      </a>
                    </li>
                    <li className="font-size-sm">Infomedika</li>
                    <li className="font-size-sm">
                      <a href="/blog/better-patient-experiences-with-bridge-patient-portals-mckesson-interface/">
                        McKesson
                      </a>
                    </li>
                    <li className="font-size-sm">MedWorxs</li>
                    <li className="font-size-sm">Mirth Connect</li>
                    <li className="font-size-sm">
                      <a href="/blog/better-patient-experiences-with-bridge-patient-portals-nextgen-interface/">
                        NextGen
                      </a>
                    </li>
                    <li className="font-size-sm">PhoenixOrtho</li>
                    <li className="font-size-sm">PrognoCIS</li>
                    <li className="font-size-sm">Strateq Health</li>
                    <li className="font-size-sm">Wellsky</li>
                    <li className="font-size-sm">Wellsoft</li>
                    <li className="font-size-sm">X-Link</li>
                  </ul>
                  <p className="font-size-sm">
                    Don’t see your preferred system listed here? Bridge has
                    extensive experience in meeting interfacing challenges.{' '}
                    <a href="/view-a-demo/">Contact us</a> to discuss a custom
                    integration.
                  </p>
                </FaqSingle>
                <FaqSingle question="How will I be charged?">
                  <p className="font-size-sm">
                    Fees are charged on the first of each month for the upcoming
                    month. If a patient or provider count is being used to
                    calculate the monthly cost, it will be based on the highest
                    number of registered patients and/or providers in the
                    previous month.
                  </p>
                </FaqSingle>
                <FaqSingle question="Must healthcare organizations purchase the entire solution?">
                  <p className="font-size-sm">
                    Bridge Essential includes the following modules:
                  </p>
                  <ul>
                    <li className="font-size-sm">Client Branding</li>
                    <li className="font-size-sm">
                      <a href="/patient-scheduling-software/">
                        Appointment Requests
                      </a>
                    </li>
                    <li className="font-size-sm">
                      <a href="/patient-portal-self-registration-login/">
                        Self Portal Registration
                      </a>
                    </li>
                    <li className="font-size-sm">Two-Factor Authentication</li>
                    <li className="font-size-sm">
                      <a href="/patient-forms-self-assessment-outcomes/">
                        Patient Forms
                      </a>
                    </li>
                    <li className="font-size-sm">
                      <a href="/patient-appointment-reminder-solution/">
                        Appointment Reminders
                      </a>
                    </li>
                    <li className="font-size-sm">
                      <a href="/patient-broadcast-mass-messaging/">
                        Broadcast & Mass Patient Messaging
                      </a>
                    </li>
                    <li className="font-size-sm">
                      <a href="/caregiver-proxy-patient-portal-access/">
                        Proxy/Family/Caregiver Accounts
                      </a>
                    </li>
                    <li className="font-size-sm">
                      <a href="/hipaa-compliant-messaging/">
                        Secure Patient Messaging
                      </a>
                    </li>
                    <li className="font-size-sm">
                      <a href="/hipaa-compliant-messaging/">Patient Surveys</a>
                    </li>
                    <li className="font-size-sm">
                      <a href="/hipaa-compliant-messaging/">
                        Rx Refill Request
                      </a>
                    </li>
                    <li className="font-size-sm">
                      <a href="/lab-results-patient-portal/">
                        Lab & Radiology Results
                      </a>
                    </li>
                    <li className="font-size-sm">
                      <a href="/online-medical-record-personal-health-record-phr-portal/">
                        Medical Records
                      </a>
                    </li>
                    <li className="font-size-sm">Patient Documents</li>
                  </ul>
                  <p className="font-size-sm">
                    Bridge also provides several optional modules, including:
                  </p>
                  <ul>
                    <li className="font-size-sm">
                      <a href="/patient-portal-software/">Mobile App</a>
                    </li>
                    <li className="font-size-sm">
                      <a href="/patient-scheduling-software/">
                        Self Scheduling
                      </a>
                    </li>
                    <li className="font-size-sm">
                      <a href="/patient-intake-software/">Patient Intake</a>
                    </li>
                    <li className="font-size-sm">
                      <a href="/revenue-cycle-management/">Bill Pay</a>
                    </li>
                    <li className="font-size-sm">
                      <a href="/healthcare-task-management/">Tasking Center</a>
                    </li>
                    <li className="font-size-sm">Telehealth</li>
                    <li className="font-size-sm">
                      <a href="/personalized-patient-education/">
                        Patient Education Integration
                      </a>
                    </li>
                  </ul>
                  <p className="font-size-sm">
                    To learn more about Bridge’s pricing structure, please{' '}
                    <a href="/view-a-demo/">contact our team</a>.
                  </p>
                </FaqSingle>
                <FaqSingle question="Does Bridge support payment plans?">
                  <p className="font-size-sm">
                    Bridge supports payment plans. Nevertheless, a lot depends
                    on the payment gateway that the client is using for
                    payments.
                  </p>
                </FaqSingle>
                <FaqSingle question="Can I request a new feature?">
                  <p className="font-size-sm">
                    Yes. Please feel free to ask us about any feature that you
                    would like to have installed. There is a good chance the{' '}
                    <a href="/patient-portal-software/">
                      patient portal development
                    </a>{' '}
                    team is already developing it or has it scheduled for
                    development.
                  </p>
                </FaqSingle>
                <FaqSingle question="What type of training is included?">
                  <p className="font-size-sm">
                    All purchases of the Bridge solution include training hours.
                    Bridge’s training sessions are typically conducted online
                    via video conferencing.
                  </p>
                  <p className="font-size-sm">
                    Many of Bridge’s clients opt for a “train the trainer”
                    approach: Bridge will train an individual or a select group
                    of people at the healthcare organization to use the portal.
                    They can then, in turn, teach the rest of the healthcare
                    staff.
                  </p>
                  <p className="font-size-sm">
                    In addition to online training, Bridge can provide clients
                    with a product manual and how-to videos explaining the
                    BridgeInteract suite’s different functions and capabilities.
                    Bridge will work with the healthcare organization to develop
                    these training materials and an overall training plan.
                  </p>
                </FaqSingle>
                <FaqSingle question="What type of support is included?">
                  <p className="font-size-sm">
                    Bridge will provide ongoing support to all clients. Support
                    includes telephone or email assistance in remedying
                    situations and problems with the operation and performance.
                    This support is available during Bridge’s regular business
                    hours, from 9:00 a.m. to 8:00 p.m. ET, Monday through
                    Friday, excluding legal holidays.
                  </p>
                  <p className="font-size-sm">
                    Unless otherwise specified, Bridge will be responsible for
                    providing Level 2 support only. Level 2 support is defined
                    as support relating to the software or server, where
                    advanced technical, development, and server administration
                    knowledge is required. This includes, but is not limited to,
                    troubleshooting software bugs, server administration, and
                    database administration. Level 1 support, defined as the
                    support provided directly to patients (i.e., login issues,
                    missing information from a medical record, etc.), is
                    generally the responsibility of the client. However,
                    arrangements can be made in circumstances where the client
                    requires Bridge to handle these support responsibilities.
                  </p>
                </FaqSingle>
                <FaqSingle question="What’s Bridge’s cancellation policy?">
                  <p className="font-size-sm">
                    Though this is contract-specific, Bridge typically requires
                    a 1-36 month commitment depending on pricing and development
                    required.
                  </p>
                </FaqSingle>
                <FaqSingle question="Can I try the product before I buy it?">
                  <p className="font-size-sm">
                    The best way to become familiar with BridgeInteract and
                    experience its benefits is through an interactive
                    demonstration with a Bridge specialist. This{' '}
                    <a href="/view-a-demo/">demo</a> is customized to the
                    client’s needs and showcases the product’s robust features
                    and capabilities.
                  </p>
                  <p className="font-size-sm">
                    In some cases, Bridge can provide a demo login to a test
                    environment so that a client can interact with the product
                    further.
                  </p>
                </FaqSingle>
                <FaqSingle question="Can custom features be added to Bridge’s web application and/or mobile app?">
                  <p className="font-size-sm">
                    Yes, we are a one-stop shop for patients to access a variety
                    of self-service tools and patient resources to better manage
                    their care. Bridge accomplishes this goal by allowing custom
                    features to be added to the patient portal’s web and mobile
                    app navigation, as well as by supporting the customization
                    of particular features. All of it can be branded to your
                    business for an even smoother experience for your patients.
                  </p>
                </FaqSingle>
                <FaqSingle question="Does Bridge support telemedicine capabilities?">
                  <p className="font-size-sm">
                    Yes. The BridgeInteract Virtual Care supports telehealth and
                    hybrid virtual/in-person workflows.
                  </p>
                </FaqSingle>
                <FaqSingle question="Do my patients have the same login and password once the portal is migrated?">
                  <p className="font-size-sm">
                    No. Bridge can’t bring over the patients’ old passwords, but
                    makes it easy for patients to self-register and set a new
                    password for their automatically created/migrated account.
                  </p>
                  <p className="font-size-sm">
                    After the portal is migrated, patients will receive a
                    welcome email. Upon clicking the URL link in the welcome
                    email, they will be invited to complete an expedited
                    registration process by verifying their identity with a
                    unique identifier.
                  </p>
                </FaqSingle>
              </Faqs>
              <p className="font-size-sm">
                Don’t see your question here? Check our comprehensive{' '}
                <a href="/faq/">FAQ page</a>
              </p>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default PatientEngagementSolution;
