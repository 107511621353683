import React from 'react';
import { string, bool, arrayOf } from 'prop-types';
import cx from 'classnames';
import Col from 'react-bootstrap/Col';
import parse from 'html-react-parser';

import circleArrowDown from '../../images/circle-arrow-down.svg';
import circleArrowUp from '../../images/circle-arrow-up.svg';

const propTypes = {
  col: string.isRequired,
  titleTop: string,
  textTop: string,
  title: string.isRequired,
  showUpArrow: bool,
  showTopSection: bool,
  grow: bool,
  texts: arrayOf(string),
};

const defaultProps = {
  titleTop: undefined,
  textTop: undefined,
  showUpArrow: false,
  showTopSection: false,
  grow: false,
  texts: [],
};

const IconCardItem = ({
  col,
  titleTop,
  textTop,
  title,
  texts,
  showUpArrow,
  showTopSection,
  grow,
}) => {
  const classes = cx(
    'c-icon-card-bottom d-flex gap-4 shadow rounded-xs bg-secondary p-4',
    { 'has-top': showTopSection },
    { 'card-height': grow },
  );

  return (
    <Col className="c-icon-card-item mb-4 mb-lg-0" lg={col}>
      {showTopSection && (
        <div className="c-icon-card-top border border-bottom-0 p-4">
          <h4 className="fw-bolder mb-2">{titleTop}</h4>
          <p className="font-size-sm mb-0">{textTop}</p>
        </div>
      )}
      <div className={classes}>
        {showUpArrow ? (
          <img src={circleArrowUp} alt="Arrow Up" />
        ) : (
          <img src={circleArrowDown} alt="Arrow Down" />
        )}
        <div>
          <h4 className="text-white fw-bolder mb-2">{title}</h4>
          <ul className="list-unstyled m-0">
            {texts.map((text) => (
              <li key={text} className="font-size-sm text-white py-0">
                {parse(text)}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Col>
  );
};

IconCardItem.propTypes = propTypes;
IconCardItem.defaultProps = defaultProps;

export default IconCardItem;
