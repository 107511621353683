import React from 'react';
import { string } from 'prop-types';

const propTypes = {
  title: string.isRequired,
  text: string.isRequired,
  uri: string,
  logo: string.isRequired,
  button: string.isRequired,
};

const defaultProps = {
  uri: undefined,
};

const LogoCardItem = ({ title, text, uri, logo, button, ...props }) => {
  return (
    <div className="c-logo-card h-100 rounded-xs p-3" {...props}>
      <div className="c-logo-card-logo mb-3">
        <img src={logo} alt="" className="h-100" />
      </div>
      <div>
        <p className="font-size-sm fw-bold mb-1">{title}</p>
        <p className="">{text}</p>
        {uri && (
          <a href={uri} target="_blank" rel="noreferrer">
            {button}
          </a>
        )}
      </div>
    </div>
  );
};

LogoCardItem.propTypes = propTypes;
LogoCardItem.defaultProps = defaultProps;

export default LogoCardItem;
